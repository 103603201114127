import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start
// import AsyncStorage from "@react-native-async-storage/async-storage";
import NetInfo from "@react-native-community/netinfo";
import { Alert } from "react-native";
import DocumentPicker from 'react-native-document-picker';
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  imgSource: string;
  imgFile: Blob | string;
  previewData: AttachmentData[];
  isConnected: null | boolean;
  imageUriFromServer: undefined | string;
  localUriFromDoc: undefined | string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start



interface ApiData {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: FormData;
  type?: string;
}

interface FormDataValue {
  uri: string;
  name: string;
  type: string;
}

interface FormData {
  append(name: string, value: string | Blob | FormDataValue, fileName?: string): void;
  delete(name: string): void;
  get(name: string): FormDataEntryValue | null;
  getAll(name: string): FormDataEntryValue[];
  has(name: string): boolean;
  set(name: string, value: string | Blob | FormDataValue, fileName?: string): void;
}

declare let FormData: {
  prototype: FormData;
  new(form?: HTMLFormElement): FormData;
};

interface FormData {
  entries(): IterableIterator<[string, string | File]>;
  keys(): IterableIterator<string>;
  values(): IterableIterator<string | File>;
  [Symbol.iterator](): IterableIterator<string | File>;
}
export interface AttachmentData {
  attachment_id: number;
  url: string;
  account: {
    id: number;
    first_name: string;
    last_name: string;
    full_phone_number: string;
    country_code: null | string;
    phone_number: null | string;
    email: string;
    activated: boolean;
    device_id: null | string;
    unique_auth_id: null | string;
    password_digest: string;
    created_at: string;
    updated_at: string;
    user_name: null | string;
    platform: null | string;
    user_type: null | string;
    app_language_id: null | string;
    last_visit_at: null | string;
    is_blacklisted: boolean;
    suspend_until: null | string;
    status: string;
    stripe_id: null | string;
    subscription_id: null | string;
    subscription_date: null | string;
    full_name: string;
    role_id: null | string;
    gender: null | string;
    date_of_birth: null | string;
    age: null | string;
    is_paid: boolean;
    new_email: null | string;
    is_deleted: boolean;
    language: null | string;
    reset_email_token: null | string;
    change_email_token: null | string;
    last_login_at: string;
    push_notificable: boolean;
    mail_notificable: boolean;
    is_mail_verified: boolean;
    devices_type: null | string;
    ios_version: null | string;
    duration: null | string;
  }
}
// Customizable Area End

export default class OfflineWorkController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  submitImg: string;
  webPing: ReturnType<typeof setInterval>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.handleConnectionChange = this.handleConnectionChange.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      imgSource: "",
      imgFile: "",
      previewData: [],
      isConnected: true,
      imageUriFromServer: "",
      localUriFromDoc: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.submitImg = "";
    this.webPing = setInterval(() => { }, 1);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiSuccessResponse(message);
      this.handleApiErrorResponse(message);
    }
    // Customizable Area End
  }
  // Customizable Area Start

  apiCall = async (data: ApiData) => {
    const { contentType, method, endPoint, body, type } = data
    let token: string | null = localStorage.getItem("LOGIN_TOKEN")

    const header = {

      token,
      'Content-Type': contentType
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )

      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getApiCommonResponseDetail = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    )
    return { apiRequestCallId, responseJson, errorReponse }
  }

  handleApiSuccessResponse = (message: Message) => {
    const { apiRequestCallId, responseJson, errorReponse } = this.getApiCommonResponseDetail(message);
    if (responseJson && !responseJson.errors && !errorReponse) {

      if (apiRequestCallId === this.submitImg) {
        this.submitImgSuccessCallBack(responseJson.data)
      }
    }
  }

  handleApiErrorResponse = (message: Message) => {
    const { apiRequestCallId, responseJson, errorReponse } = this.getApiCommonResponseDetail(message);
    if (responseJson?.errors || !errorReponse?.errors) {
      if (apiRequestCallId === this.submitImg) {

        this.submitImgFailureCallBack(responseJson?.errors || errorReponse?.errors)
      }
    }
  }

  async componentDidMount() {
    await this.getLocalUri();

    this.handleConnectionChangeMobile();
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  }

  async componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  handleConnectionChange = async () => {

    if (this.state.imgSource) {
      localStorage.setItem("imgSource", this.state.imgSource)
      const condition = navigator.onLine ? 'online' : 'offline';
      const body = new FormData();
      body.append("attachment", this.state.imgFile);
      if (condition === 'online') {
        this.webPing = setInterval(
          async () => {
            this.submitImg = await this.apiCall({
              method: configJSON.apiPostMethod,
              endPoint: configJSON.apiEndPointSubmitImage,
              body,
              type: 'formData'
            })
          }, 2000);
      }
    }
  }


  onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let files = event.target.files;
    if (files && files.length > 0) {
      this.setState({ imgSource: URL.createObjectURL(files[0]), imgFile: files[0] });
    }
  }

  submitImgSuccessCallBack = (attachmentData: AttachmentData[]) => {
    localStorage.removeItem("imgSource");
    clearInterval(this.webPing);

    this.setImageUriFromServer(attachmentData[0].url)

    this.setState({ imgSource: "", imgFile: "", previewData: attachmentData });

    this.showAlert("OfflineWork", "Image Upload Successfully")
  };


  submitImgFailureCallBack = (errorReponse: string) => {
    clearInterval(this.webPing);
    this.setState({ imgSource: "", imgFile: "" });
    this.parseApiCatchErrorResponse(errorReponse);
  };


  handleConnectionChangeMobile = async () => {

    NetInfo.fetch().then(state => {
      this.setState({ isConnected: state.isConnected });
    });


    if (this.state.localUriFromDoc) {

      const formData = new FormData();


      formData.append("attachment", {
        uri: this.state.localUriFromDoc,
        type: "image/png",
        name: `${Math.random() * 100000000000000000}.png`,
      });

      if (this.state.isConnected === true) {
        const makeCall = async () => {
          this.submitImg = await this.apiCall({
            method: configJSON.apiPostMethod,
            endPoint: configJSON.apiEndPointForOfflineWork,
            body: formData,
            type: 'formData',
            contentType: 'multipart/form-data'
          })
        }
        await makeCall();
      }

    }

  }

  selectOneFile = async () => {

    try {
      const response = await DocumentPicker.pick({
        type: [DocumentPicker.types.images],
      });

      // (await AsyncStorage.setItem('localUri',response[0].uri),
      // await this.getLocalUri())




    } catch (error) {
      if (DocumentPicker.isCancel(error)) {
        Alert.alert('Canceled from image doc picker');
      } else {
        Alert.alert('Unknown Error: ' + JSON.stringify(error));
        throw error;
      }
    }
  };

  setImageUriFromServer = (imageUri: string) => {
    this.setState({ imageUriFromServer: imageUri })
  }

  getLocalUri = async () => {

  }
  uploadImage = async () => {
    await this.selectOneFile(), this.handleConnectionChangeMobile()
  }
  // Customizable Area End
}
