import React from "react";
import { Redirect } from "react-router-dom";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import OfflineWorkController, {
  Props,
  configJSON,
} from "./OfflineWorkController";

export default class OfflineWork extends OfflineWorkController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    if(!localStorage.getItem("LOGIN_TOKEN")){
      return <Redirect to='/email-account-login' />
    }
    return (
       // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box>
            <Box sx={webStyle.inputStyle}>
              <Box sx={webStyle.uploadBox}>
                <BackupOutlinedIcon style={webStyle.uploadIcon} />
                <input
                  data-test-id={"fileInput"}
                  type= "file"
                  onChange={(event) => {this.onChange(event)}}
                  style={webStyle.uploadInput}
                  accept=".png,.jpg,.jpeg"
                />
                <Typography style={webStyle.choosePhoto}>{configJSON.ChooseProfilePicture}</Typography>
              </Box>
              
              {this.state.imgSource && <Box sx={webStyle.previewImg}>
                <img data-test-id="imgPreview" style={webStyle.recentImg} src={this.state.imgSource.toString()} alt="preview-img" />
              </Box>}
              {this.state.imgSource && <Box
                component="button"
                sx={webStyle.buttonStyle}
              >
                <Button 
                data-test-id="uploadImage"
                color={"primary"} onClick={this.handleConnectionChange}>{configJSON.UploadPicture}</Button>
              </Box>}
            </Box>
            <Box sx={webStyle.previewImg}>
              {
              this.state.previewData.map((pdata) => {
                return <img key={pdata.attachment_id} style={webStyle.recentImg} data-test-id="imgPreview" src={pdata.url} alt="preview-img" width="50%" height="50%"/>
              })
              }
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  inputStyle: {
    boxShadow: '0px 0px 10px #979797',
    padding: "25px",
    marginTop: "20%",
    marginBottom: "20px",
    borderRadius: "8px"
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  uploadBox: {
    border: "1px dotted #d1d1d1",
    background: "#F9f9f9",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
    padding: "20px"
  },
  uploadIcon: {
    fontSize: "100px"
  },
  uploadInput: {
    opacity: 0,
    zIndex: 9,
    cursor: "pointer"
  },
  choosePhoto: {
    position: "absolute" as const,
    bottom: "22px",
    zIndex: 0,
    cursor: "pointer",
    textDecoration: "underline",
    color: "#6f0c97"
  },
  previewImg: {
    height: "fit-content",
    marginTop: "20px",
    display: "flex",
    justifyContent: "center"
  },
  recentImg: {
    width: "auto",
    height: "auto"
  }
};
// Customizable Area End
