import React, { Component } from "react";
import { selectMenuIcon } from "../assets";
import CustomTypographyWeb from "../CustomTypography.web";
import { Checkbox, IconButton, Input, CircularProgress } from "@material-ui/core";
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

interface Option {
  value: string;
  label: string;
}

interface LoadAutocompleteSelectProps {
  multipleSelection?: boolean;
  placeholder: string;
  value: string;
  loading: boolean;
  error?: boolean;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  helperText?: string;
  options: Option[];
  showClear?: boolean;
  onSearch?: (value: string) => void;
  onChange: (value: string) => void;
}

interface LoadAutocompleteSelectState {
  isOpen: boolean;
  searchTerm: string;
  hoveredItem: string;
  selectedValue: string;
  highlightedIndex: number;
}


class LoadAutocompleteSelect extends Component<
LoadAutocompleteSelectProps,
LoadAutocompleteSelectState
> {
  private autocompleteSelectRef: React.RefObject<HTMLInputElement>;
  private inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: LoadAutocompleteSelectProps) {
    super(props);
    this.autocompleteSelectRef = React.createRef();
    this.inputRef = React.createRef();
    this.state = {
      isOpen: false,
      searchTerm: "",
      hoveredItem: "",
      selectedValue: this.props.value,
      highlightedIndex: -1,
    };

  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const { isOpen } = this.state;
    if (isOpen) this.setState({ isOpen: false });
  };

  clearOptions = () => {
    this.setState({
      searchTerm: "",
      hoveredItem: "",
      selectedValue: this.props.value ? this.props.value : "",
    });
    if (this.inputRef.current) this.inputRef.current.focus();
  };

  handleClickOutside = (event: MouseEvent) => {
    const { selectedValue, isOpen } = this.state;
    if (
      this.autocompleteSelectRef.current &&
      !this.autocompleteSelectRef.current.contains(event.target as Node) &&
      isOpen
    ) {
      if (selectedValue !== "" && selectedValue !== this.props.value) {
        if (this.props.multipleSelection) {
          this.setState({
            isOpen: false,
            highlightedIndex: -1,
            searchTerm: ''
          });
        } else {
          const { options, onChange } = this.props;
          const matchedOption = options.find(
            (option) =>
              option.label.toLowerCase() === selectedValue.toLowerCase()
          );
          if (matchedOption) {
            onChange(matchedOption.value);
            this.setState({ isOpen: false, highlightedIndex: -1, selectedValue: matchedOption.label });
          }else {
            this.setState({
              isOpen: false,
              highlightedIndex: -1,
              selectedValue: this.props.value,
            });
          }
        }
      } else {
        if (selectedValue === this.props.value) {
          this.setState({
            isOpen: false,
            highlightedIndex: -1,
            searchTerm: ''
          });
        }
        if (selectedValue === "" && isOpen) {
          this.props.onChange("");
          this.setState({
            isOpen: false,
            highlightedIndex: -1,
            selectedValue: "",
            searchTerm: ''
          });
        }
      }
    }
  };

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.multipleSelection) {
      this.setState({
        searchTerm: event.target.value,
        isOpen: true,
        selectedValue: event.target.value,
        highlightedIndex: -1,
      });
    } else {
      this.setState({
        searchTerm: event.target.value,
        isOpen: true,
        selectedValue: event.target.value,
        highlightedIndex: -1,
      });
    }


    if (this.props?.onSearch) {
      this.props.onSearch(event.target.value)
    }
      
  };


  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { isOpen, searchTerm, highlightedIndex } = this.state;
    if (
      isOpen &&
      (event.key === "ArrowUp" ||
        event.key === "ArrowDown" ||
        event.key === "Enter")
    ) {
      event.preventDefault();
      const filteredOptions = this.props.options.filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
      if (filteredOptions.length === 0) return;
      switch (event.key) {
        case "ArrowUp": {
          if (
            highlightedIndex !== -1 &&
            highlightedIndex >= filteredOptions.length
          ) {
            this.setState({ highlightedIndex: highlightedIndex - 1 });
          }
          break;
        }
        case "ArrowDown": {
          if (highlightedIndex <= filteredOptions.length)
            this.setState({ highlightedIndex: highlightedIndex + 1 });
          break;
        }
        case "Enter": {
          const option = filteredOptions[highlightedIndex];
          if (option) {
            this.handleOnClick(option.label);
          } else {
            this.handleOnClick("");
          }
          break;
        }
        default:
          return;
      }
    }
  };

  handleOnClick = (value?: string) => {
    const { isOpen, selectedValue } = this.state;
    if ((isOpen && value) || (isOpen && value === "")) {
      let updatedSelectedValue = "";
      if (this.props.multipleSelection) {
        // If it's multiple selection, toggle the selected value
        const selectedValues =
          selectedValue === "" ? [] : selectedValue.split(",");
        const index = selectedValues.indexOf(value);
        if (index === -1) {
          // Value not found, add it
          selectedValues.push(value);
        } else {
          // Value found, remove it
          selectedValues.splice(index, 1);
        }
        updatedSelectedValue = selectedValues.join(",");
        this.setState({ selectedValue: updatedSelectedValue });
        this.props.onChange(updatedSelectedValue);
      } else {
        this.setState({ selectedValue: value, isOpen: false, searchTerm: "" });
        this.props.onChange(value);
      }
    } else {
      this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
    }
    if (this.inputRef.current) this.inputRef.current.blur();
  };

  handleInputFocus = () => {
    if (!this.state.isOpen) {
      this.setState({ isOpen: true });
    }
  };

  render() {
    const { options, multipleSelection, error, helperText, showClear, loading } = this.props;
    const { isOpen, searchTerm, selectedValue, highlightedIndex } = this.state;
    const filteredOptions = options;
    const selectedOption = options?.find((option: any) => option.value === selectedValue);

    return (
      <div style={cssStyles.selectContainer} ref={this.autocompleteSelectRef}>
        <Input
          disableUnderline
          style={{
            ...cssStyles.select,
            border: error
              ? "1px solid #DC2626"
              : isOpen
              ? "1px solid #0094A7"
              : cssStyles.select.border,
          }}
          type="text"
          onChange={this.handleInputChange}
          onFocus={this.handleInputFocus}
          placeholder={this.props.placeholder}
          onKeyDown={this.handleKeyDown}
          value={
            selectedOption?.label
          }
          ref={this.inputRef}
          endAdornment = { selectedValue && showClear &&
             <IconButton style={{padding: 0}} onClick={ () => {
                this.setState({
                  selectedValue: ""
                })
                this.props.onChange("");
             }}>
                <CancelRoundedIcon />
             </IconButton>
          }
          error={error}
          startAdornment={this.props.startAdornment}
        />
        <div
          style={{
            position: "absolute",
            top: "12px",
            right: "12px",
            cursor: "pointer",
          }}
          onClick={() => {
            this.setState({ isOpen: !isOpen });
          }}
        >
          <img
            src={selectMenuIcon}
            width={24}
            height={24}
            style={{ cursor: "pointer" }}
          />
        </div>
        {error && (
          <p
            style={{
              position: "absolute",
              fontFamily: "Source Sans Pro",
              margin: 0,
              textAlign: "left",
              fontWeight: 400,
              lineHeight: 1.66,
              letterSpacing: "0.03em",
              fontSize: "0.75rem",
              color: "#f44336",
            }}
          >
            {helperText}
          </p>
        )}
        {multipleSelection && (
          <div
          style={{
            ...cssStyles.menuMultipleSelectionCount,
            display:
              multipleSelection && selectedValue.split(",").length > 1
                ? "block"
                : "none",
          }}
        >
          + {selectedValue.toString().split(",").length}{" "}
        </div>
        )}
        { loading && this.state.searchTerm.length > 0 && 
            <div style={{ ...cssStyles.menu, display: isOpen ? "flex" : "none", textAlign: 'center', background: 'white', overflow: 'none', height: 100, alignItems: 'center', justifyContent: 'center', backgroundColor: '#f6f6f6' }}>
              <CircularProgress
                color="inherit"
                size={"2rem"}
                style={{ color: "black", display: 'inline-block' }}
              />
            </div>
        
          }
          { !loading && selectedValue.length > 0 &&
          <div style={{ ...cssStyles.menu, display: isOpen ? "block" : "none" }}>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <div
                  key={option.value}
                  style={{
                    ...cssStyles.menuItem,
                    ...(this.state.hoveredItem === option.value
                      ? cssStyles.menuItemHover
                      : {}),
                    ...(index === highlightedIndex
                      ? cssStyles.menuItemHover
                      : {}),
                  }}
                  onMouseEnter={() =>
                    this.setState({ hoveredItem: option.value })
                  }
                  onMouseLeave={() => this.setState({ hoveredItem: "" })}
                  onClick={() => {
                    this.handleOnClick(option.value);
                  }}
                >
                  {multipleSelection && (
                    <Checkbox
                      checked={this.props.value.includes(option.label)}
                      readOnly
                      tabIndex={-1}
                      style={cssStyles.multipleSelectionCheckbox}
                    />
                  )}
                  <CustomTypographyWeb isContent>
                    {option.label}
                  </CustomTypographyWeb>
                </div>
              ))
            ) : (
              <div
                key={"not-found"}
                style={{
                  ...cssStyles.menuItem,
                  ...(this.state.hoveredItem === "not-found"
                    ? cssStyles.menuItemHover
                    : {}),
                }}
                onMouseEnter={() => this.setState({ hoveredItem: "not-found" })}
                onMouseLeave={() => this.setState({ hoveredItem: "" })}
                onClick={() => {
                  this.clearOptions();
                }}
              >
                <CustomTypographyWeb isContent>
                  Search not found
                </CustomTypographyWeb>
              </div>
            )}
          </div>
        }
        
      </div>
    );
  }
}

export default LoadAutocompleteSelect;

const cssStyles = {
  selectContainer: {
    position: "relative",
    width: "100%",
  } as React.CSSProperties,
  select: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    lineHeight: "16px",
    fontSize: "16px",
    fontWeight: 400,
    color: "#2B3132",
    width: "100%",
    padding: "6px 30px 6px 6px",
    border: "1px solid #9F9F9F",
    borderRadius: "8px",
    cursor: "pointer",
  } as React.CSSProperties,
  menu: {
    position: "absolute",
    top: "100%",
    left: 0,
    width: "100%",
    border: "1px solid #9F9F9F",
    borderRadius: "8px",
    maxHeight: "250px",
    overflowY: "auto",
    zIndex: 100,
    display: "none",
    marginTop: "1px",
  } as React.CSSProperties,
  menuItem: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    cursor: "pointer",
    backgroundColor: "#F6F6F6",
    transition: "background-color 0.3s",
  } as React.CSSProperties,
  menuItemHover: {
    backgroundColor: "#D9D9D9",
  } as React.CSSProperties,
  menuMultipleSelectionCount: {
    position: "absolute",
    top: "50%",
    right: "40px",
    transform: "translateY(-50%)",
    backgroundColor: "#0094A7",
    borderRadius: "35px",
    color: "#FFF",
    padding: "2px 3px",
  } as React.CSSProperties,
  multipleSelectionCheckbox: {
    color: "#0094A7",
  } as React.CSSProperties,
};
