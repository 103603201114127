import { Message } from '../../framework/src/Message';
import MessageEnum, { getName } from '../../framework/src/Messages/MessageEnum';
import { useBlockHelpers } from '../../blocks/utilities/src/hooks/useBlockHelpers';
import StorageProvider from '../../framework/src/StorageProvider'
import { removeStorageData } from '../../framework/src/Utilities';
import { runEngine } from '../../framework/src/RunEngine';
import { favEmpty, favFilled } from '../../blocks/visualanalytics/src/assets';
import moment from 'moment';
import dayjs from 'dayjs';
import * as Yup from 'yup';

const countries = require("countries-list");
const ConfigJSON = require("../../framework/src/config");


export interface ErrorObject {
  [key: string]: string;
}

export const logoutUser = async (navigation: any, error?: string) => {
    const { showAlert } = useBlockHelpers()

    const message = () => {
        const navigateToLoginMessage: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        navigateToLoginMessage.addData(getName(MessageEnum.NavigationTargetMessage), "LoginBlock");
        navigateToLoginMessage.addData(getName(MessageEnum.NavigationPropsMessage), navigation);

        return navigateToLoginMessage
    }

    if (error) {
        showAlert('Error', error, 'OK', message(), undefined, undefined, undefined, undefined, navigation)
    } else {
      const token = await localStorage.getItem("authToken");
      let requestLogout
      if (token) {
        const headers = new Headers({
          'Content-Type': 'application/json',
          'Token': token
        });
        requestLogout = await fetch(ConfigJSON.baseURL + "/account_block/accounts/logout",{
          method: 'PUT',
          headers,
        });
      }
      if (StorageProvider) {
        const keysToKeep = ["ProfertEmail", "ProfertPassword"];
        Object.keys(localStorage).forEach(async(key) => {
            if (!keysToKeep.includes(key)) {
              await removeStorageData(key);
            }
        });
      }
      runEngine.sendMessage(message().id, message())
      return requestLogout
    }
}

export const removeUndefinedPropertiesFromGivenObject = (obj:any) => {
  const result:any = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value !== undefined && value !==null) {
      result[key] = value;
    }
  }
  return result;
}

export function truncateText(text: string, maxLength: number) {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  }
  return text;
}

export const getProjectModuleCompanyDetails = (attributes:any, id:string)=>{
  return {
    client: attributes?.client,
    client_name: attributes?.client_name,
    country: attributes?.country,
    currency_value: attributes?.currency_value,
    fee: attributes?.fee,
    group_company: attributes?.group_company,
    sector: attributes?.sector_name,
    type_of_currency: attributes?.type_of_currency,
    value_of_work_range: attributes?.value_of_work_range,
    company_detail_id: id,
  }
}
export const getProjectModuleProjectDetails = (projectDetails: any, company_details: any, connections: any, service_type: any, iconValue:any )=>{
  return {
    project_name: truncateText(projectDetails?.project_name, 30),
    id: projectDetails.id,
    project_title: projectDetails?.title,
    project_code: projectDetails?.code,
    joint_venture: projectDetails?.joint_venture,
    joint_venture_share: projectDetails?.joint_venture_share,
    technical_summary: projectDetails?.technical_summary,
    profile_id: projectDetails?.project_id,
    service_type,
    services: company_details?.services,
    start_date: projectDetails?.start_date,
    end_date: projectDetails?.end_date,
    iconValue,
    favourite: projectDetails?.favourite,
    project_status: projectDetails?.project_status,
    ...connections,
    ...company_details,


  }
}

export const prepareProjectModuleTableResult = (data: any[])=>{
  return data.map((project: any) => {
    let company_details: any = {};
    let service_type: string = "";
    let iconValue: any = favEmpty;
    const { attributes: projectDetails } = project;
    if (
      projectDetails.company_detail &&
      projectDetails.company_detail.data &&
      projectDetails.company_detail.data.id
    ) {
      const attributes = projectDetails.company_detail.data.attributes;
      company_details = Object.assign({}, getProjectModuleCompanyDetails(
        attributes,
        projectDetails.company_detail.data.id
      ));
      if (attributes.sector && attributes.sector.id) {
        company_details.sector = attributes.sector.name;
      }
      if (attributes.services && Array.isArray(attributes.services.data)) {
        const services = attributes.services.data
          .map((service: any) => service.attributes.name)
          .join(",");
        company_details.services = truncateText(services, 25);
      }
    }
    if (projectDetails.service_type && projectDetails.service_type.data) {
      service_type = projectDetails.service_type.data.attributes.name;
    }

    if (projectDetails.favourite && projectDetails.favourite.is_favourite) {
      iconValue = favFilled;
    }

    const viewedProjectDetails = getProjectModuleProjectDetails(
      projectDetails,
      company_details,
      project.attributes.connections,
      service_type,
      iconValue
    );
    return removeUndefinedPropertiesFromGivenObject(viewedProjectDetails);
  });
}

export const parseCompanyDetailsProjectModule = ( data: any) => {
  const projectDetails = data.attributes;
  let company_details: any = {};
  if (
    projectDetails.company_detail &&
    projectDetails.company_detail.data &&
    projectDetails.company_detail.data.id
  ) {
    const attributes = projectDetails.company_detail.data.attributes;
    company_details = {
      client: attributes.client ? attributes.client : "",
      client_name: attributes.client_name ? attributes.client_name : "",
      country: attributes.country ? attributes.country : "",
      currency_value: attributes.currency_value
        ? attributes.currency_value
        : "",
      fee: attributes.fee ? attributes.fee : "0",
      group_company: attributes.group_company
        ? attributes.group_company
        : "",
      sector: attributes.sector_name ?? "",
      type_of_currency: attributes.type_of_currency
        ? attributes.type_of_currency
        : "",
      value_of_work: attributes.value_of_work
        ? attributes.value_of_work
        : "",
      value_of_work_range: attributes.value_of_work_range ? attributes.value_of_work_range : "",
      value_of_work_usd: attributes.value_of_work_usd ? attributes.value_of_work_usd : "0",
      company_detail_id: data.attributes.company_detail.data.id,
    };
    if (attributes.sector && attributes.sector.id) {
      company_details.sector = attributes.sector.name;
    }
    if (attributes.services && Array.isArray(attributes.services.data)) {
      const services = attributes.services.data
        .map((service: any) => service.attributes.name)
        .join(",");
      company_details.services = services;
    }
  }
  return company_details;
}

export const parseViewProjectDetails = (projectDetails: any, company_details: any, service_type: any) => {
  const certificates = projectDetails.certificates;
  const gallery = projectDetails.images;
  return {
    project_name: projectDetails.project_name
      ? projectDetails.project_name
      : "",
    projectId: projectDetails.id,
    project_title: projectDetails.title ? projectDetails.title : "",
    project_code: projectDetails.code ? projectDetails.code : "",
    joint_venture: projectDetails.joint_venture
      ? projectDetails.joint_venture
      : "",
    joint_venture_share: projectDetails.joint_venture_share
      ? projectDetails.joint_venture_share
      : "",
    technical_summary: projectDetails.technical_summary
      ? projectDetails.technical_summary
      : "",
    profile_id: projectDetails.project_id ?? "",
    service_type,
    project_status: projectDetails.project_status ?? "",
    start_date: projectDetails.start_date ? projectDetails?.start_date : "",
    end_date: projectDetails.end_date ? projectDetails.end_date : "",
    certificates: Array.isArray(certificates) ? certificates : [],
    gallery: Array.isArray(gallery) ? gallery : [],
    ...company_details,
  };
};

export const validateDateRange = (inputDateString: string | Date, isPastDate?: boolean, isFutureDate?: boolean, text?: string) => {
  if (!inputDateString) {
    return { error: false };
  }

  const inputDate = dayjs(inputDateString, "DD-MM-YYYY", true).isValid()
    ? dayjs(inputDateString, "DD-MM-YYYY").startOf('day')
    : dayjs(inputDateString).startOf('day');
  
  if (!inputDate.isValid()) {
    return { error: true, text: "Invalid date format." };
  }

  const currentDate = dayjs().startOf('day');
  const minimumAllowedDate = dayjs('1900-01-01').startOf('day');

  if (inputDate.isBefore(minimumAllowedDate)) {
    return { error: true, text: "Year should start from 1900." };
  }

  if (isPastDate && inputDate.isBefore(currentDate)) {
    return { error: true, text: "You can select today or a future date." };
  }

  if (isFutureDate && inputDate.isAfter(currentDate)) {
    return { error: true, text: text || "You can select a past date." };
  }

  return { error: false };
};

export const mergeUpdateRecordRelation = (originRelation: any, type:'related' | 'unrelated', newGroupRelaton: any ) => {

  const value = newGroupRelaton.filter((obj:any) => Object.keys(obj).length > 0) || [];
  return [...value];
}

export const customSort = ({ group, menu }: any) => {
  if(!group && !menu){
    return []
  }

  const getLabelIndex = (label: any) => menu.findIndex((item:any) => item.label === label);

    return group
      .slice() // Create a shallow copy to avoid modifying the original array
      .sort((a:any, b:any) => {
        const labelAIndex = getLabelIndex(a.employe_status);
        const labelBIndex = getLabelIndex(b.employe_status);

        // If either label is not found in menu, place it at the end
        if (labelAIndex === -1) return 1;
        if (labelBIndex === -1) return -1;

        return labelAIndex - labelBIndex;
      });
};

export const downloadFileByContent = (filename: string, blob: Blob) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
}

export const downloadFromLink = (filename: string, link: string) =>  {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = link;
  a.download = filename;
  a.target = "_blank"
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(link);
  document.body.removeChild(a);
}

export const getProgressCSVDownload = async (exportToken: string, userToken: string) => {

  const requestProgressDownload = await fetch(ConfigJSON.baseURL + "/bx_block_data_import_export/export/get_people_csv_status?activity_type=project&export_token=" + exportToken, { headers: { token: userToken } });

  return requestProgressDownload.json();
}

export const downloadCSVContent = async (exportToken: string, userToken: string, activity_type: string) => {
  const requestContentDownload = await fetch(`${ConfigJSON.baseURL}/bx_block_data_import_export/export/exported_people_csv?activity_type=${activity_type}&export_token=${exportToken}`, { headers: { token: userToken } });
  
  return requestContentDownload.json();
}

export const getProgressPDFDownload = async (exportToken: string, userToken: string, activity_type: string) => {

  const requestProgressDownload = await fetch(`${ConfigJSON.baseURL}/bx_block_data_import_export/export/get_people_pdf_status?activity_type=${activity_type}&export_token=${exportToken}`, { headers: { token: userToken } });
  return requestProgressDownload.json();
}

export const downloadPDFContent = async (exportToken: string, userToken: string) => {
  const requestContentDownload = await fetch(ConfigJSON.baseURL + "/bx_block_data_import_export/export/exported_people_dashboard_pdf?export_token=" + exportToken, { headers: { token: userToken } });
  
  return requestContentDownload.json();
}

export const importProjectStatus = async (exportToken: string, userToken: string, activity_type: string) => {
  const requestContentDownload = await fetch(`${ConfigJSON.baseURL}/bx_block_data_import_export/export/import_status?activity_type=${activity_type}&import_token=${exportToken}`, { headers: { token: userToken } });
  
  return requestContentDownload.json();
}

export const fetchBlobData = async (path: string) => {
  try {
    const response = await fetch(path);
    const blob = await response.blob();
    const file = new File([blob], 'default.jpg', { type: blob.type });
    return file;
  } catch (error) {
    console.error("Error fetching image:", error);
    throw error; 
  }
};

export const compareSVG = (localSvgPath: string, apiUrl: any): Promise<boolean> => {
  return new Promise((resolve) => {
    // Fetch local SVG
    fetch(localSvgPath)
      .then((response) => response.text())
      .then((localSvgContent) => {
        // Fetch SVG from API
        fetch(apiUrl)
          .then((response) => response.text())
          .then((apiSvgContent) => {
            // Compare SVG content
            const areSVGsIdentical = localSvgContent === apiSvgContent;
            if (areSVGsIdentical) {
              console.log('SVGs are identical.');
            } else {
              console.log('SVGs are different.');
            }
            resolve(areSVGsIdentical);
          })
          .catch((error) => {
            console.error('Error fetching API SVG:', error);
            resolve(false);
          });
      })
      .catch((error) => {
        console.error('Error fetching local SVG:', error);
        resolve(false);
      });
  });
};

export const setCheckedToFalseDeep = (input: any, options: { selectedKey?: string, selectedValue?: any } = {}): any => {
  const { selectedKey = 'isChecked', selectedValue = false } = options;

  if (Array.isArray(input)) {
    return input.map(item => setCheckedToFalseDeep(item, options));
  } else if (typeof input === 'object' && input !== null) {
    const result: any = {};
    for (const key in input) {
      if (Object.prototype.hasOwnProperty.call(input, key)) {
        result[key] = setCheckedToFalseDeep(input[key], options);
      }
    }
    if (selectedKey in input) {
      result[selectedKey] = selectedValue;
    }
    return result;
  }
  // For anything else, return as is
  return input;
};

export interface LabelValue {
  value: string;
  label: string;
}

export const convertResIntoLabelValue = (res: any, key: string = "name", elementsToRemove?: string[]) => {
  if (!res || !Array.isArray(res)) {
    return [];
  }

  const isAll = (item: LabelValue) => item.value === "All";
  const isOther = (item: LabelValue) => (item.value === "Other" || item.value === "Others");

  const mapToLabelValue = (r: any): LabelValue => ({
    value: r[key],
    label: r[key],
  });

  const shouldRemove = (item: LabelValue) => elementsToRemove?.includes(item.value);

  const filteredArr = res
    .map(mapToLabelValue)
    .filter((item) => !shouldRemove(item))
    .sort((a, b) => {
      if (isAll(a)) return -1;
      if (isAll(b)) return 1;
      if (isOther(a)) return 1;
      if (isOther(b)) return -1;
      return a.value.localeCompare(b.value);
    });

  return filteredArr;
};

export const convertResIntoLabelValueCheck = (list: any, id:number, label:string, res: any, key: string = "name") => {
  const array = convertResIntoLabelValue(res, key);
  if(array.length === 0) return list;

  const result = array.map((obj:any)=>{
    return {
      ...obj, isChecked: false
    }
  })

  const updatedArray = list.map((item:any) => {
    if (item.id === id) {
      const updatedOptions = (item.filterOption[label] || []).reduce((acc:any, option:any) => {
        const exists = result.some((res) => res.label === option.label);
        return exists ? acc : [...acc, option];
      }, result);

      const newList =  updatedOptions.slice()
      .sort((a:any, b:any) => {
        if (a.label.toLowerCase() === "all") return -1;
        if (b.label.toLowerCase() === "all") return 1;
        if (a.label.toLowerCase() === "other" || a.label.toLowerCase() === "others") return 1;
        if (b.label.toLowerCase() === "other" || b.label.toLowerCase() === "others") return -1;
        return a.label.localeCompare(b.label);
      });

      return {
        ...item,
        filterOption: {
          ...item.filterOption,
          [label]: newList
        },
      };
    }
    return item;
  });

  return updatedArray;
};

export function compareObjects(o1: any, o2: any){
  for(let p in o1){
      if(o1.hasOwnProperty(p)){
          if(o1[p] !== o2[p]){
              return false;
          }
      }
  }
  for(let p2 in o2){
      if(o2.hasOwnProperty(p2)){
          if(o1[p2] !== o2[p2]){
              return false;
          }
      }
  }
  return true;
}  

export const totalFilterCountFromObject = (filters: {}) => {
  const { totalSum, trueCount } = Object.entries(filters).reduce(
    (acc, [, value]) => {
      acc.totalSum += Array.isArray(value) ? value.length : 0;
      acc.trueCount += value === true ? 1 : 0;
      return acc;
    },
    { totalSum: 0, trueCount: 0 }
  );
  return totalSum + trueCount;
}

export const parseSaveSearchResponse = (data:any[], id: string | number) => {
  if(!data || !Array.isArray(data)) return [];
  const res = data.map((item: any) => {
    const { attributes, id } = item;
    const filterCounter = totalFilterCountFromObject(attributes.filters);
    return {
      id,
      ...attributes,
      filterCounter,
    };
  })

  const indexInFirstFive = res.slice(0, 5).findIndex((item) => item.id === id);

  if (indexInFirstFive !== -1) {
    return res;
  } else {
    const indexInArray = res.findIndex((item) => item.id === id);
    if (indexInArray !== -1) {
      const itemToMove = res.splice(indexInArray, 1)[0];
      res.unshift(itemToMove);
    }
    return res;
  }
} 

export const listFirstSelectedSaveSearch = (res: any, id: string | number) => {
  const indexInFirstFive = res.slice(0, 5).findIndex((item :any) => item.id === id);

  if (indexInFirstFive !== -1) {
    return res;
  } else {
    const indexInArray = res.findIndex((item :any) => item.id === id);
    if (indexInArray !== -1) {
      const itemToMove = res.splice(indexInArray, 1)[0];
      res.unshift(itemToMove);
    }
    return res;
  }
}

export const parseFilterListObject = (filterList:{[key:string]:any}) => {
  const filteredResult:{[key:string]:any}  = {};
  let sortValue:string = ""
  for (const [key, value] of Object.entries(filterList)) {
    if (value !== "" && !(Array.isArray(value) && value.length === 0) && value !== false) {
      filteredResult[key] = value;
    }
    if (key === "sort_key") {
      sortValue = value
    }
  }
  !sortValue && delete filteredResult["sort_direction"];

  return filteredResult;
}

export const setCheckedToTrueDeep = (list: any[], filters:any) => {
  const processFilterOption = (filterOption: any) => {
    filterOption = setCheckedToFalseDeep(filterOption);

    for (const key in filters) {
      const options = filterOption[key];
      if (options && options.length > 0) {
        options.forEach((option:any) => {
          option.isChecked = filters[key] === true || filters[key].includes(option.value) || filters[key].includes(option.label);
        });
      }
    }
    return filterOption;
  };

  const processFilterOptionForRadio = (filterOptionForRadio: any) => {
    filterOptionForRadio = setCheckedToFalseDeep(filterOptionForRadio);

    for (const relationType in filterOptionForRadio) {
      const relationOptions = filterOptionForRadio[relationType];

      for (const relationKey in relationOptions) {
        const renderValue = relationOptions[relationKey].render;

        if (filters[renderValue] && filters[renderValue].length > 0) {
          relationOptions[relationKey].optionsList.forEach((option: any) => {
            option.isChecked = filters[renderValue].includes(option.value);
          });
        }
      }
    }
    return filterOptionForRadio;
  };

  return list.map((item) => {
    if (item.filterOption) {
      item.filterOption = processFilterOption(item.filterOption);
    }

    if (item.radioOption && item.filterOptionForRadio) {
      item.filterOptionForRadio = processFilterOptionForRadio(item.filterOptionForRadio);
    }

    return item;
  });
}

export const checkValueInList = (list: any[], key: string, value: string, fallBackValue?: string) => {
  try {
    const foundItem = list.find(item => item[key] == value);
    
    if (foundItem) {
      return value
    } else {
      return fallBackValue
    }
  } catch (error) {
    return fallBackValue || ""
  }
}
export const buildUrlParams = (obj: any) => {
  const queryParams = new URLSearchParams();

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const dataArray = obj[key];

      if (dataArray.length > 0) {
        if ((key === "favourites" || key === "favourite") && dataArray.length > 0) {
          dataArray[0] && queryParams.append(key, 'true');
        } else {
          const filteredArray = dataArray.filter((value:string) => value.trim() !== "");

          if (filteredArray.length > 0) {
            filteredArray.forEach((value: any) => {
              queryParams.append(`${key}[]`, value);
            });
          }
        }
      }
    }
  }

  return queryParams;
}

export const mergeFilterObjects = ( oldFilters: any, newFilters: any) => {
  const mergedFilters = { ...oldFilters };
  for (const key in newFilters) {
    if (newFilters.hasOwnProperty(key)) {
      const newValue = newFilters[key];

      if (Array.isArray(newValue)) {
        mergedFilters[key] = Array.from(new Set([...(mergedFilters[key] || []), ...newValue]));
      } else if (typeof newValue === 'object' && newValue !== null) {
        mergedFilters[key] = mergeFilterObjects(mergedFilters[key] || {}, newValue);
      } else {
        mergedFilters[key] = newValue;
      }
    }
  }

  return mergedFilters;
}

export const allTimeZoneList = Object.keys(countries.countries).map((code) => ({
  code,
  name: countries.countries[code].name,
  dial_code: `+${countries.countries[code].phone[0]}`,
}));

export const determineSelectedGroupRelation = (filters: Record<string, any>) => {
  const relationKeys = Object.keys(filters).filter((key) => key.startsWith("related["));

  if (relationKeys.length > 0) {
    return "related";
  } else if ("unrelated" in filters) {
    return "unrelated";
  } else {
    return "";
  }
};

export const countCheckedTrue = (input: any, options: { selectedKey?: string } = {}): number => {
  const { selectedKey = 'isChecked' } = options;

  let count = 0;

  const checkAndCount = (item: any): void => {
    if (typeof item === 'object' && item !== null) {
      for (const key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          if (key === selectedKey && item[key] === true) {
            count++;
          } else {
            checkAndCount(item[key]);
          }
        }
      }
    } else if (Array.isArray(item)) {
      for (const arrayItem of item) {
        checkAndCount(arrayItem);
      }
    }
  };

  checkAndCount(input);

  return count;
};

export const convertExactRoles = (roles:any[]) =>  {
  if(Array.isArray(roles) && roles.length === 0) return [];
  const result = roles?.map((role:any) => {
    return typeof role === 'string' ? { role_name: role } : role
  })
  return result
}
export const getUnmatchedIds = (arr1:any, arr2:any) =>  {
  const idsInArr1 = arr1.map((obj1:any) => obj1.id);
  const unmatchedIds = arr2.filter((obj2:any) => !idsInArr1.includes(obj2.id)).map((obj2:any) => obj2.id);
  return unmatchedIds;
}

export const changeHtmltoText = (data: string) =>  {
  const html = document.createElement('div');
	const htmlData = html;
	htmlData.innerHTML = data

  if(data === "<p><br></p>" || data === "") {
    return ""
  } else {
    return html.innerHTML || "";
  }
}

export const checkHtmlContentLength = (data: string | undefined | null) =>  {
  const doc = new DOMParser().parseFromString(data ?? "", 'text/html');
  if(data === "<p><br></p>" || data === "") {
    return ""
  } else {
    let imgCount = 0;
    let textContent = "";
    doc.querySelectorAll('img').forEach(img => {
      imgCount++;
    })
  
    if(imgCount === 1) {
      textContent += "image file" + " "
    } else if(imgCount > 1 ){
      textContent += "image files" + " "
    }

    const bodyTextContent = doc.body.textContent;
    if (bodyTextContent) {
      textContent += bodyTextContent ? (bodyTextContent + ' '): "";
    }

    return textContent.trim() || "";
  }
}

export const parseErrorResponse = (errors: any, errorK: string) => {
  const errorPr = errors.some((error: ErrorObject) => error.hasOwnProperty(errorK))
  const textPr = errors.find((x: ErrorObject) => x.hasOwnProperty(errorK))?.[errorK] ?? undefined
  return {error: errorPr, text : textPr }
}

export const bindingCloseEvent = (childrenTriggerEvent: any, parrentTriggerEvent: any) => {
 
  return (section: any) => {
    childrenTriggerEvent(section)
    parrentTriggerEvent(section)  
  }
  
}

export function capitalizeFirstLetter(string: string | undefined | null) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

export const dateRangeComparison = ( from: Date | null, to: Date | null ) => {
  if(from && to){
    if (from > to) return "From date is after To date.";
    if (from === to) return "From date is equal to To date.";
  }
  return ""
};

export const buildReqFiltersURLParams = (obj: any) => {
  const queryParams = new URLSearchParams();
  const { module_name, status, actioned_date, requested_date} = obj;

  const appendDateRange = (prefix: string, range: { From?: any; To?: any }) => {
    if (range && range.From) {
      const value = moment(range.From).format("DD/MM/yyyy");
      queryParams.append(`${prefix}[from]`, value);
    }
    if (range && range.To) {
      const value = moment(range.To).format("DD/MM/yyyy");
      queryParams.append(`${prefix}[to]`, value);
    }
  };

  const appendArrayValues = (key: string, values?: string[]) => {
    if (values && values.length > 0) {
        values.forEach(value => {
            queryParams.append(`${key}[]`, value);
        });
    }
  };

  appendArrayValues("module_name", module_name);
  appendArrayValues("status", status);
  appendDateRange("actioned_date", actioned_date);
  appendDateRange("requested_date", requested_date);

  return queryParams;
}

export const countRequestFilters = (obj: any) => {
  let newCount = totalFilterCountFromObject(obj);
  for (const value of Object.values(obj)) {
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      const nestedValues = Object.values(value);
      if (nestedValues.every(val => val !== null && val !== undefined)) {
        newCount++;
      }
    }
  }
  return newCount
}

export const setDisableForObject = (obj: {[key:string]: any}): boolean  => {
  let hasNonNullValue: boolean = false;
  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "" && obj[key] !== false) {
        if (Array.isArray(obj[key]) && obj[key].length > 0) {
            return true;
        } else if (obj[key] !== null && obj[key] instanceof Date) {
            return true;
        } else if (typeof obj[key] === 'object') {
            if (setDisableForObject(obj[key])) {
                return true;
            }
        } else {
            hasNonNullValue = true;
        }
    }
  }
    return hasNonNullValue;
}

export const shuffleSet = (setColors: Set<any>, number: number) => {
  const array = Array.from(setColors); // Convert Set to Array
  for (let colorI = array.length - 1; colorI > 0; colorI--) {
    const colorJ = Math.floor(Math.random() * (colorI + 1));
    [array[colorI], array[colorJ]] = [array[colorJ], array[colorI]];
  }
  return array.slice(0, number); // Return only the specified number of elements
};

type DataMapping = { [key: string]: any };

function getNestedObjectValue(obj: any, path: string): any {
  if (!obj) return undefined; // Immediately return if the initial object is null/undefined
  const keys = path.split('.');
  let current = obj;
  for (const key of keys) {
      if (current === undefined || current === null || !(key in current)) {
          return undefined;
      }
      current = current[key];
  }
  return current;
}

export function mapConnectionTableField(selectedData: any, mapping: DataMapping): typeof mapping {
    const result: any = {};
    Object.keys(mapping).forEach((newKey) => {
        const valuePath = mapping[newKey];
        const value = getNestedObjectValue(selectedData, valuePath);
        // If the value is undefined, it means the path was not found; skip setting this key
        result[newKey] = value !== undefined ? value : null;
        
    });
    return result;
}

export function countSection(sections: string[]) {
  const sectionCache = sections.reduce((acc, current, index) => {
    return {
      ...acc,
      [current]: index == 0 ? 1 : 0
    }
  }, {}) as {[key in string]: number}


  return {
    sectionCache,
    get: (activeSection: string) => {
      return sectionCache[activeSection]
    },
    actived: (activeSection: string) => {
      return sectionCache[activeSection] >= 1
    },
    setActive: (activeSection: string) => {
      sectionCache[activeSection] = sectionCache[activeSection] + 1
    }

  }  
}

export function handleSectionNavigateLogic(targetId: string, condition: boolean, successResult: string, failedResult: string) {
  return targetId == 'add' && condition ? successResult : failedResult
}
export const getQuarterOptions = (numQuarters: number) : {label: string; value: number; values: any}[] => {
  const quarters: {label: string; value: number; values: {[key: string]: number | string}}[] = [];
  const now = new Date();
  const estOffset = 5 * 60 * 60 * 1000;
  const currentEstTime = new Date(now.getTime() - estOffset);
  let year = currentEstTime.getFullYear();
  let month = currentEstTime.getMonth() + 1;
  let quarter: number;

  for (let i:number = 0; i < numQuarters; i++) {
    if (month <= 3) {
      quarter = 1;
    } else if (month <= 6) {
      quarter = 2;
    } else if (month <= 9) {
      quarter = 3;
    } else {
      quarter = 4;
    }
    quarters.unshift({ label: `Q${quarter} ${year}`, value: i, values:{ quarter, year }});

    if (quarter === 1) {
      year--;
      month = 10; 
    } else {
      month -= 3;
    }
  }

  return quarters.reverse();
};

export const objectToQueryParams = (obj: any): string => {
  return Object.entries(obj)
  .filter(([_, value]) => value !== null && value !== undefined)
  .map(([key, value]: any) => {
        if (value instanceof Date) {
          const estDate = new Date(value.toLocaleString("en-US", {timeZone: "America/New_York"}));
          const formattedDate = `${estDate.getMonth() + 1}/${estDate.getDate()}/${estDate.getFullYear()}`;
          return `${encodeURIComponent(key)}=${encodeURIComponent(formattedDate)}`;
        } else {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      })
      .join('&');
}

export const transformHistogramData = (data: { [key: string]: { [key: string]: { count: number } } }): any[] => {
  if(Object.keys(data).length > 0) {
    const row = Object.keys(Object.values(data)[0])
    const firstRow= ["Company", ...row];
    const result: any[] = [firstRow];

    Object.entries(data).forEach(([company, counts]) => {
      const companyCounts: any[] = [company];
      firstRow.slice(1).forEach(range => {
        companyCounts.push(counts[range]?.count || 0);
      });
      result.push(companyCounts);
    });

    return result;
  } else {
    return [["company","0-1 MLN USD","1-3 MLN USD","3-7 MLN USD","7-15 MLN USD","15-... MLN USD"]]
  }
};

export function comparedLatestStatus(statuses: boolean[]) {
  return statuses.reduce((acc, status) => acc && status, true)

}

export const calculateDonutFontSize = () => {

  const windowSize = window.innerWidth;

  if (windowSize <= 400) return 10;

  if (windowSize <= 960) return 12;

  if (windowSize <= 1080) return 7;

  if (windowSize <= 1220) return 8;

  if (windowSize <= 1320) return 9;

  if (windowSize <= 1580) return 10;

  return 12; 
};

function hasInvalidImageTag(html: string): boolean {
  // Regular expression to match all src attributes of <img> tags
  const imgSrcRegex = /<img\s+[^>]*src="([^"]+)"[^>]*>/gi;

  // Regular expression to match valid data:image/* base64 strings
  const validDataImageRegex = /^data:image\/[^;]+;base64,/i;

  let match;
  while ((match = imgSrcRegex.exec(html)) !== null) {
      const src = match[1];
      if (!validDataImageRegex.test(src)) {
          return true; 
      }
  }

  return false; 
}

export const validateHtmlContent = (htmlContent: string): string => {

	const urlWithoutProtocolRegex = /<a\s+(?:[^>]*?\s+)?href=(["'])([^"'\s:\/]{2,})\1/i;

  const strippedContent = htmlContent.replace(/<p>(.*?)<\/p>/s, '$1');
  const trimmedContent = strippedContent.trim();

  const brPattern: RegExp = /<p><br><\/p><p><br><\/p>/g;
  const cleanedContent: string = htmlContent.replace(brPattern, "");

  if(htmlContent === "<p><br></p>" || cleanedContent.length === 0) {
    return ""
  }
  

  if (countWordsInHtml(htmlContent) > 2000) {
    return "Word limit should not exceed 2000 word"
  }

  if (trimmedContent.length === 0) {
		return "Should not support only spaces";
	}
	if (urlWithoutProtocolRegex.test(htmlContent)) {
		return "Please enter a valid URL starting with 'http://' or 'https://'";
	}

  if (containsInvalidLinks(htmlContent)) {
    return "Please enter a valid URL"
  }

  if (hasInvalidImageTag(htmlContent)) {
    return "Please upload valid image"
  }
  
	return "";
}

export function countWordsInHtml(htmlString: string): number {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  let wordCount = 0;

  function traverseNodes(node: Node): void {
    if (node.nodeType === Node.TEXT_NODE) {
      const words = (node.textContent || '').split(/\s+/);
      wordCount += words.filter(word => word.length > 0).length;
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      node.childNodes.forEach(traverseNodes);
    }
  }

  traverseNodes(doc.body);

  return wordCount;
}


export function cutDownLimitWords(htmlString: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const serializer = new XMLSerializer();

  let wordCount = 0;
  let reachedLimit = false;

  function traverseNodes(node: Node): void {
    if (reachedLimit) return;

    if (node.nodeType === Node.TEXT_NODE) {
      const words = (node.textContent || '').split(/\s+/);
      if (wordCount + words.length > 2000) {
        node.textContent = words.slice(0, 2000 - wordCount).join(' ');
        reachedLimit = true;
      } else {
        wordCount += words.length;
      }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      for (let i = 0; i < node.childNodes.length; i++) {
        traverseNodes(node.childNodes[i]);
        if (reachedLimit) {
          while (node.childNodes.length > i + 1) {
            node.removeChild(node.childNodes[i + 1]);
          }
          break;
        }
      }
    }
  }

  traverseNodes(doc.body);

  return serializer.serializeToString(doc.body);
}

const containsInvalidLinks = (htmlContent: string): boolean => {
  const html = document.createElement('div');
  html.innerHTML = htmlContent;
  const invalidLinks = html.querySelectorAll('a');
  
  for (const link of invalidLinks) {
    const href = link.getAttribute('href');
    if (!isValidLink(href as string)) {
      return true;
    }
  }
  return false;
}

export const isValidLink = (link: string): boolean => {
  const linkData = link
  const urlPattern = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/
  const linkDataTest = urlPattern.test(linkData);
  return linkDataTest;
}

export function extractConfirmationParams(url: string): { token: string, lang: string } | null {
  const tokenRegex = /token=([^&]+)/;
  const langRegex = /lang=([^&]+)/;

  const tokenMatches = url.match(tokenRegex);
  const langMatches = url.match(langRegex);

  if (tokenMatches && tokenMatches.length >= 2 && langMatches && langMatches.length >= 2) {
    const token = tokenMatches[1];
    const lang = langMatches[1];
    return { token, lang };
  } else {
    return null;
  }
}

export function extractCountryCode(input: string): string | null {
  const countryCodePattern = /\+\d{1,4}/;
  const match = input.match(countryCodePattern);
  return match ? match[0] : null;
}

export function removeErrorsActiveKey(errors: any, activeKey: string, mapConfigs: { [key: string]: string } ) {
  const actualKey = mapConfigs?.[activeKey];
  const filteredErrors = errors.filter((error: Object) => {
    return !error.hasOwnProperty(actualKey)
  });

  return filteredErrors
}

export const removeKeyErros = (errors: {[key:string]:string}[], key: string) => {
  const isHaveKeyError = errors.find((error) => error.hasOwnProperty(key))
  if (isHaveKeyError) {
    return errors.filter((error) => key != Object.keys(error)[0])
  } else {
    return errors
  }
}

export const validateAndUpdateErrors = (
  schema: Yup.ObjectSchema<any>,
  errors: ErrorObject[],
  key: string,
  value: any
) => {
  const validKeys = Object.keys(schema.describe().fields);
  const seenKeys = new Set<string>();
  const uniqueErrors = errors.filter((errorObj) => {
    const errorKey = Object.keys(errorObj)[0];
    if (!seenKeys.has(errorKey)) {
      seenKeys.add(errorKey);
      return true;
    }
    return false;
  });

  const filteredErrors = uniqueErrors.filter((errorObj) => {
    const errorKey = Object.keys(errorObj)[0];
    return validKeys.includes(errorKey);
  });

  if (!validKeys.includes(key)) {
    return filteredErrors.filter(errorObj => !(key in errorObj));
  }

  try {
    schema.validateSyncAt(key, { [key]: value });
    return filteredErrors.filter(errorObj => !(key in errorObj));
  } catch (err) {
    if (err instanceof Yup.ValidationError) {
      const error = err;
      const updatedErrors = filteredErrors.map((errorObj: ErrorObject) => {
        if (key in errorObj) {
          return { ...errorObj, [key]: error.message };
        }
        return errorObj;
      });

      if (!filteredErrors.some(errorObj => key in errorObj)) {
        updatedErrors.push({ [key]: err.message });
      }

      return updatedErrors;
    }
    throw err;
  }
}

export const validateAll = ( 
  schema: Yup.ObjectSchema<any>,
  object: {[key: string]: any}) => {

    try {

      const schemaFields = Object.keys(schema.fields);
      const filteredObject = Object.keys(object)
      .filter(key => schemaFields.includes(key))
      .reduce((acc: any, key) => {
        acc[key] = object[key];
        return acc;
      }, {});
  
      schema.validateSync(filteredObject, { abortEarly: false });

      return [];

    } catch (validationError) {
      if (validationError instanceof Yup.ValidationError) {
        const errors = validationError.inner.reduce((acc: any, err) => {
          if (err.path && err.message) {
            acc.push({ [err.path]: err.message });
          }
          return acc;
        }, []);
        return errors;
      }
    }
}
export function toArrayQuery(key: string, data: number[]) {
  
  const queryParams = Array.from(data).map((item: number, index: number) => {
    return `${encodeURIComponent(key)}[]=${encodeURIComponent(item.toString())}`;
  }).join('&');

  return queryParams;
}
export function queryArray(paramName: string, url: string) {
  const urlObj = new URL(url);
  const params = urlObj.searchParams;
  const result = [];

  for (const [key, value] of params.entries()) {
    if (key === `${paramName}[]`) {
      result.push(value);
    }
  }

  return result;
}

export function checkLoadTimes(): number {
  const path = document.location.pathname;
  const storageKey = `loadCount_${path}`;
  
  const storedCount = sessionStorage.getItem(storageKey);
  const count = storedCount ? parseInt(storedCount, 10) : 0;

  const newCount = count + 1;

  sessionStorage.setItem(storageKey, newCount.toString());

  return newCount;
}